@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

@media only screen and (min-width : 1024px) {
.archive__item-excerpt {
  margin-top: 0;
  font-size:0.79em!important;

}
.btn, #goog-wm-sb {
    font-size: .79em!important;
}
.page__related {
    float: none!important; 
    width: 100%!important;
}
.grid__item .archive__item-teaser {
 max-height: none!important;
}
li > p {
    font-size: 1em!important;
}

li li{
    font-size: 1em !important
}
}

.btn--light-outline {
    background: #37627bc7!important;
	text-shadow: 1px 1px 4px rgba(0,0,0,0.5)!important;
}
.page__lead {
    text-shadow: 2px 2px 1px #464646!important;
}
.page__hero--overlay .page__title, .page__hero--overlay .page__meta, .page__hero--overlay .comment__date, .page__hero--overlay .page__lead, .page__hero--overlay .btn, .page__hero--overlay #goog-wm-sb {
    color: #fff;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.83)!important;
}

.layout--splash .archive__item-title {
font-size: 19px;	
color: #4e80a3
}
.layout--splash #main > article > section > div:nth-child(2) .archive__item-title {
text-transform: uppercase;	
}
.layout--splash .archive__item-teaser img {
    width: 132px!important;
}
.layout--splash .archive__item {
    text-align: center;
}
.layout--splash .btn--inverse {
    background-color: #d5874c!important;
    color: #ffffff!important;
    text-shadow: 1px 1px 1px #000;
}
.layout--splash #main > article > section > div:nth-child(3) .btn--inverse {
    background: #dfeffb!important;
    color: #000!important;
    text-shadow: 1px 1px 1px #ddd;
    font-weight: 700!important;
}
.layout--splash #main > article > section > div:nth-child(3) {
    clear: both;
    margin-bottom: 2em;
    border-bottom: 1px solid #f2f3f3;
    background: #4c7ea3;
    color: #fff;
	padding: 20px 0;
}
.layout--splash #main > article > section > div:nth-child(3) img {
width: 375px!important;
}
.layout--splash .feature__item--left .archive__item-teaser {
margin-bottom: unset!important;
}
.layout--splash #main > article > section > div:nth-child(4) {
margin-top:3em!important;
	
}
.layout--splash #main > article > section > div:nth-child(4) img {
width: 375px!important;
}
.layout--splash #main > article > section > div:nth-child(4) .archive__item {
    text-align: left;
	 float: none!important;
}
.layout--splash #main > article > section > div:nth-child(4) li {
list-style-type:none;
padding: 10px 0;
}
.layout--splash #main > article > section > div:nth-child(4) ul {
padding:0;
}
.layout--splash #main > article > section > div:nth-child(4) .archive__item-title {color:#000;}


.layout--splash #main > article > section > div:nth-child(4) .btn--inverse {
    background-color: #97c2ff !important;
}
.layout--splash svg:not(:root).svg-inline--fa {
    color: #4d7fa3;
}

.layout--splash #main > article > section > div:nth-child(5) img {
    width: 531px !important;
}
.sim2 {
    background-color: #f2f8fb;
    margin: .5em 0;
    padding: 1em 4% 1px 3em;
    padding-left: calc(2em + 2%);
    border-radius: 15px;
}
.sim3 {
    background-color: #fbf2f4;
    margin: .5em 0;
    padding: 1em 4% 1px 3em;
    padding-left: calc(2em + 2%);
    border-radius: 15px;
}
.sim2 svg {
    color: #3a5898;
    font-size: 18px;
}
.num {
    counter-reset: list 0;
}
.num > li {
    border-bottom: 1px solid #e2e2e2;
    list-style: outside none none;
    padding: 5px 5px 10px 30px;
    position: relative;
}
.num li:before {
    background: #3a5898;
    left: -1px;
    position: absolute;
    text-align: center;
    top: 7px;
    color: #fff;
    content: counter(list,decimal) " ";
    counter-increment: list;
    font-weight: bold;
    width: 26px;
    border-radius: 50%;
    height: 26px;
}
.nev {
    color: #888;
}
.img-tit {
    text-align: center;
    display: block;
}
.img-tit.nev {
margin-top: -20px;
}
.page-bottom {
height:420px;		
}
.diclamer {font-size:10px;}
.imgcen {text-align:center;}
.ban {width:400px;}



.page__content svg  {margin-right: 8px;}
.mar {margin:20px 0;}



#main > article > div.page__inner-wrap > section.page__content > p:nth-child(43) > a {word-break: break-all;}